var GetContactWith = /* @__PURE__ */ ((GetContactWith2) => {
  GetContactWith2["Contribution"] = "contribution";
  GetContactWith2["Profile"] = "profile";
  GetContactWith2["Roles"] = "roles";
  GetContactWith2["Tags"] = "tags";
  GetContactWith2["IsReviewer"] = "isReviewer";
  return GetContactWith2;
})(GetContactWith || {});
export {
  GetContactWith
};
