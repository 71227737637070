import { BaseClient } from "./base.client.js";
import { cleanUrl } from "../utils/index.js";
class ContentClient extends BaseClient {
  /**
   * Creates a new content client
   * @param options - The client options
   */
  constructor(options) {
    super({
      ...options,
      path: cleanUrl(options.path + "/content")
    });
    this.options = options;
  }
  /**
   * Deserializes content data from the server response
   * @template Id - The type of content identifier
   * @param content - The content data to deserialize
   * @returns The deserialized content
   */
  static deserialize(content) {
    return content;
  }
  /**
   * Retrieves content by its identifier
   * @template Id - The type of content identifier
   * @param id - The content identifier (slug or ID)
   * @returns The content data
   */
  async get(id) {
    const { data } = await this.fetch.get(`/${id}`);
    return data;
  }
  /**
   * Updates existing content
   * @template Id - The type of content identifier
   * @param id - The content identifier
   * @param content - Partial content data to update
   * @returns The server response
   */
  async update(id, content) {
    const { data } = await this.fetch.patch(`/${id}`, content);
    return data;
  }
}
export {
  ContentClient
};
