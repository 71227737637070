var CalloutComponentBaseType = /* @__PURE__ */ ((CalloutComponentBaseType2) => {
  CalloutComponentBaseType2[CalloutComponentBaseType2["CONTENT"] = 0] = "CONTENT";
  CalloutComponentBaseType2[CalloutComponentBaseType2["INPUT"] = 1] = "INPUT";
  CalloutComponentBaseType2[CalloutComponentBaseType2["INPUT_TEXT"] = 2] = "INPUT_TEXT";
  CalloutComponentBaseType2[CalloutComponentBaseType2["INPUT_SELECTABLE"] = 3] = "INPUT_SELECTABLE";
  CalloutComponentBaseType2[CalloutComponentBaseType2["NESTABLE"] = 4] = "NESTABLE";
  return CalloutComponentBaseType2;
})(CalloutComponentBaseType || {});
export {
  CalloutComponentBaseType
};
