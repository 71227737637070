import { isURL } from "../utils/index.js";
import { isFileUploadAnswer } from "../utils/callouts.js";
const calloutComponentInputFileValidator = (_schema, answer) => {
  if (isFileUploadAnswer(answer)) {
    return isURL(answer.url);
  }
  return false;
};
export {
  calloutComponentInputFileValidator
};
