import { isTextInRange, isTextInWordRange } from "../utils/index.js";
const validateRules = (rules, answer) => {
  if (!rules) {
    return true;
  }
  if (!answer && rules.required) {
    return false;
  }
  if (typeof rules.pattern === "string" && rules.pattern.length && !new RegExp(rules.pattern).test(answer || "")) {
    return false;
  }
  if (!isTextInWordRange(answer, rules.minWords, rules.maxWords)) {
    return false;
  }
  if (!isTextInRange(answer, rules.minLength, rules.maxLength)) {
    return false;
  }
  return true;
};
const calloutComponentInputTextValidator = (schema, answer) => {
  if (typeof answer !== "string") {
    return false;
  }
  return validateRules(schema?.validate, answer);
};
export {
  calloutComponentInputTextValidator
};
