import { TagClient } from "./tag.client.js";
class CalloutTagClient extends TagClient {
  /**
   * Creates a new callout tag client
   * @param options - The client options
   */
  constructor(options) {
    super(options);
    this.options = options;
  }
  /**
   * Gets the base path for tag operations on a specific callout
   * Used internally to construct API endpoints
   * @param entityId - The ID or slug of the callout
   * @returns The API path for callout tag operations
   */
  getBasePath(entityId) {
    return `/callout/${entityId}/tags`;
  }
}
export {
  CalloutTagClient
};
