import { BaseClient } from "./base.client.js";
import { ContactClient } from "./contact.client.js";
import { cleanUrl } from "../utils/index.js";
class AuthClient extends BaseClient {
  /**
   * Creates a new authentication client
   * @param options The client options
   */
  constructor(options) {
    super({
      ...options,
      path: cleanUrl(options.path + "/auth")
    });
    this.options = options;
  }
  /**
   * Authenticates a user with credentials
   * @param data Login credentials including email, password and optional 2FA token
   * @returns Promise that resolves when login is successful
   * @throws ClientApiError with code REQUIRES_2FA if 2FA is required
   */
  async login(data) {
    await this.fetch.post(
      "login",
      {
        email: data.email,
        password: data.password,
        token: data.token
      },
      {
        credentials: "include"
      }
    );
  }
  /**
   * Gets the current authentication information
   * @returns Promise that resolves with the auth info
   */
  async info() {
    const { data } = await this.fetch.get("info", {
      credentials: "include"
    });
    if (data.contact) {
      data.contact = ContactClient.deserialize(data.contact);
    }
    return data;
  }
  /**
   * Logs out the current user
   * Ends the user session and removes authentication
   * @returns Promise that resolves when logout is complete
   */
  async logout() {
    await this.fetch.post("logout", void 0, {
      credentials: "include"
    });
    this.fetch.clearCookies();
  }
}
export {
  AuthClient
};
