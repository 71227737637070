var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import { BaseClient } from "./base.client.js";
import { cleanUrl } from "../utils/index.js";
import { ContactMfaClient } from "./contact-mfa.client.js";
import { ContactContributionClient } from "./contact-contribution.client.js";
import { ContactRoleClient } from "./contact-role.client.js";
import { ContactTagClient } from "./contact-tag.client.js";
import { ContactPaymentClient } from "./contact-payment.client.js";
class ContactClient extends BaseClient {
  /**
   * Creates a new contact client with all sub-clients
   * @param options - The client options
   */
  constructor(options) {
    super({
      ...options,
      path: cleanUrl(options.path + "/contact")
    });
    this.options = options;
    /** Client for managing multi-factor authentication */
    __publicField(this, "mfa");
    /** Client for managing contact contributions */
    __publicField(this, "contribution");
    /** Client for managing contact roles */
    __publicField(this, "role");
    /** Client for managing contact tags */
    __publicField(this, "tag");
    /** Client for managing contact payments */
    __publicField(this, "payment");
    this.mfa = new ContactMfaClient(options);
    this.contribution = new ContactContributionClient(options);
    this.role = new ContactRoleClient(options);
    this.tag = new ContactTagClient(options);
    this.payment = new ContactPaymentClient(options);
  }
  /**
   * Deserializes contact data from the server response
   * Handles dates, nested objects, and computes display name
   * @param contact - The serialized contact data
   * @returns Deserialized contact with computed fields
   */
  static deserialize(contact) {
    return {
      ...contact,
      displayName: `${contact.firstname} ${contact.lastname}`.trim() || contact.email,
      joined: ContactClient.deserializeDate(contact.joined),
      lastSeen: ContactClient.deserializeDate(contact.lastSeen),
      ...contact.contribution && {
        contribution: ContactContributionClient.deserialize(
          contact.contribution
        )
      },
      ...contact.roles && {
        roles: contact.roles.map(
          (role) => ContactRoleClient.deserialize(role)
        )
      }
    };
  }
  /**
   * Lists contacts with optional filtering and relations
   * @param query - Query parameters for filtering contacts
   * @param _with - Optional relations to include
   * @returns Paginated list of contacts
   */
  async list(query = {}, _with) {
    const { data } = await this.fetch.get(
      "/",
      { with: _with, ...query }
    );
    return {
      ...data,
      items: data.items.map(
        (item) => ContactClient.deserialize(item)
      )
    };
  }
  /**
   * Creates a new contact
   * @param newData - The contact data to create
   * @returns The created contact
   */
  async create(newData) {
    const { data } = await this.fetch.post("/", newData);
    return ContactClient.deserialize(data);
  }
  /**
   * Gets a single contact by ID
   * @param id - The contact ID
   * @param _with - Optional relations to include
   * @returns The contact data with requested relations
   */
  async get(id, _with) {
    const { data } = await this.fetch.get(`/${id}`, { with: _with });
    return ContactClient.deserialize(data);
  }
  /**
   * Updates a contact's information
   * @param id - The contact ID
   * @param updateData - The data to update
   * @returns The updated contact
   */
  async update(id, updateData) {
    const { data } = await this.fetch.patch(
      `/${id}`,
      updateData
    );
    return ContactClient.deserialize(data);
  }
  /**
   * Updates multiple contacts based on rules
   * @param rules - Rules to select which contacts to update
   * @param updates - The update data to apply
   * @returns Number of affected contacts
   */
  async updateMany(rules, updates) {
    const { data } = await this.fetch.patch("/", {
      rules,
      updates
    });
    return data;
  }
  /**
   * Deletes a contact
   * @param id - The contact ID to delete
   */
  async delete(id) {
    await this.fetch.delete(`/${id}`);
  }
}
export {
  ContactClient
};
