import { calloutComponentNestableTypes } from "./callout-component-nestable-types.js";
import { calloutComponentInputTypes } from "./callout-component-input-types.js";
import { CalloutComponentType } from "./callout-component-type.js";
const calloutComponentTypes = [
  ...calloutComponentNestableTypes,
  ...calloutComponentInputTypes,
  CalloutComponentType.CONTENT
];
export {
  calloutComponentTypes
};
