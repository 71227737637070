import { BaseClient } from "./base.client.js";
import { cleanUrl } from "../utils/index.js";
class StatsClient extends BaseClient {
  /**
   * Creates a new stats client
   * @param options - The client options
   */
  constructor(options) {
    super({
      ...options,
      path: cleanUrl(options.path + "/stats")
    });
    this.options = options;
  }
  /**
   * Fetches statistics based on query parameters
   * @param query - The query parameters for filtering stats
   * @returns The statistics data
   */
  async list(query) {
    const { data } = await this.fetch.get("", query);
    return data;
  }
}
export {
  StatsClient
};
