var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import { BaseClient } from "./base.client.js";
import { cleanUrl } from "../utils/index.js";
import { ContactClient } from "./contact.client.js";
import { CalloutResponseCommentClient } from "./callout-response-comment.client.js";
class CalloutResponseClient extends BaseClient {
  /**
   * Creates a new callout response client
   * @param options - The client options including API path
   */
  constructor(options) {
    super({
      ...options,
      path: cleanUrl(options.path + "/callout-responses")
    });
    this.options = options;
    /** Sub-client for managing comments on responses */
    __publicField(this, "comment");
    this.comment = new CalloutResponseCommentClient(options);
  }
  /**
   * Deserializes a response from the server data
   * Handles dates and nested objects like assignee, contact and latest comment
   * @param response - The serialized response data
   * @returns The deserialized response with proper date objects and nested entities
   */
  static deserialize(response) {
    return {
      ...response,
      createdAt: CalloutResponseClient.deserializeDate(response.createdAt),
      updatedAt: CalloutResponseClient.deserializeDate(response.updatedAt),
      ...response.assignee && {
        assignee: ContactClient.deserialize(response.assignee)
      },
      ...response.contact && {
        contact: ContactClient.deserialize(response.contact)
      },
      ...response.latestComment && {
        latestComment: CalloutResponseCommentClient.deserialize(
          response.latestComment
        )
      }
    };
  }
  /**
   * Lists responses to a callout with optional filtering and relations
   * Supports pagination and complex filtering rules
   * @param query - Query parameters for filtering responses
   * @param _with - Optional relations to include in the response
   * @returns A paginated list of responses
   */
  async list(query = {}, _with) {
    const { data } = await this.fetch.get("", { with: _with, ...query });
    return {
      ...data,
      items: data.items.map((item) => CalloutResponseClient.deserialize(item))
    };
  }
  /**
   * Bulk updates responses based on rules
   * Allows updating multiple responses that match certain criteria
   * @param rules - Rules to select which responses to update
   * @param updates - The update data to apply
   * @returns The number of affected responses
   */
  async updates(rules, updates) {
    const { data } = await this.fetch.patch("", {
      rules,
      updates
    });
    return data;
  }
  /**
   * Gets a single response by ID
   * @param id - The ID of the response to retrieve
   * @param _with - Optional relations to include
   * @returns The response data with requested relations
   */
  async get(id, _with) {
    const { data } = await this.fetch.get(`/${id}`, void 0, { params: { with: _with } });
    return CalloutResponseClient.deserialize(data);
  }
  /**
   * Updates a single response
   * Can update status, assignee, and other metadata
   * @param id - The ID of the response to update
   * @param dataIn - The update data
   * @returns The updated response
   */
  async update(id, dataIn) {
    const { data } = await this.fetch.patch(
      `/${id}`,
      dataIn
    );
    return CalloutResponseClient.deserialize(data);
  }
}
export {
  CalloutResponseClient
};
