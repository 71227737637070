import { ItemStatus } from "../data/index.js";
const calloutFilters = {
  id: {
    type: "text"
  },
  slug: {
    type: "text"
  },
  title: {
    type: "text"
  },
  status: {
    type: "enum",
    options: [
      ItemStatus.Draft,
      ItemStatus.Scheduled,
      ItemStatus.Open,
      ItemStatus.Ended
    ]
  },
  answeredBy: {
    type: "contact"
  },
  starts: {
    type: "date",
    nullable: true
  },
  expires: {
    type: "date",
    nullable: true
  },
  hidden: {
    type: "boolean"
  },
  channels: {
    type: "array"
  },
  canReview: {
    type: "boolean"
  }
};
calloutFilters;
const calloutResponseFilters = {
  id: {
    type: "text"
  },
  contact: {
    type: "contact",
    nullable: true
  },
  calloutId: {
    type: "text"
  },
  createdAt: {
    type: "date"
  },
  updatedAt: {
    type: "date"
  },
  bucket: {
    type: "text",
    nullable: true
  },
  tags: {
    type: "array"
  },
  assignee: {
    type: "contact",
    nullable: true
  },
  answers: {
    type: "blob"
  }
};
calloutResponseFilters;
const calloutResponseCommentFilters = {
  id: {
    type: "text"
  },
  calloutId: {
    type: "text"
  },
  responseId: {
    type: "text"
  },
  contact: {
    type: "contact"
  },
  createdAt: {
    type: "date"
  },
  updatedAt: {
    type: "date"
  },
  text: {
    type: "text"
  }
};
calloutResponseCommentFilters;
const calloutTagFilters = {
  // ...tagFilters,
  id: {
    type: "text"
  },
  name: {
    type: "text"
  },
  description: {
    type: "text"
  },
  calloutId: {
    type: "text"
  }
};
calloutTagFilters;
const calloutChannelFilters = {
  id: {
    type: "text"
  },
  name: {
    type: "text"
  },
  description: {
    type: "text"
  },
  calloutId: {
    type: "text"
  }
};
calloutChannelFilters;
const calloutReviewerFilters = {
  id: {
    type: "text"
  },
  contact: {
    type: "contact"
  },
  calloutId: {
    type: "text"
  }
};
calloutReviewerFilters;
export {
  calloutChannelFilters,
  calloutFilters,
  calloutResponseCommentFilters,
  calloutResponseFilters,
  calloutReviewerFilters,
  calloutTagFilters
};
