var NewsletterStatus = /* @__PURE__ */ ((NewsletterStatus2) => {
  NewsletterStatus2["Subscribed"] = "subscribed";
  NewsletterStatus2["Unsubscribed"] = "unsubscribed";
  NewsletterStatus2["Cleaned"] = "cleaned";
  NewsletterStatus2["Pending"] = "pending";
  NewsletterStatus2["None"] = "none";
  return NewsletterStatus2;
})(NewsletterStatus || {});
export {
  NewsletterStatus
};
