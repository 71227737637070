import { BaseClient } from "./base.client.js";
import { cleanUrl } from "../utils/index.js";
class ContactRoleClient extends BaseClient {
  constructor(options) {
    super({
      ...options,
      path: cleanUrl(options.path + "/contact")
    });
    this.options = options;
  }
  static deserialize(data) {
    return {
      role: data.role,
      dateAdded: this.deserializeDate(data.dateAdded),
      dateExpires: data.dateExpires ? this.deserializeDate(data.dateExpires) : null
    };
  }
  // Role methods
  async update(id, role, updateData) {
    const { data } = await this.fetch.put(`/${id}/role/${role}`, {
      dateAdded: updateData.dateAdded,
      dateExpires: updateData.dateExpires
    });
    return ContactRoleClient.deserialize(data);
  }
  async delete(id, role) {
    await this.fetch.delete(`/${id}/role/${role}`);
  }
}
export {
  ContactRoleClient
};
