import { BaseClient } from "./base.client.js";
import { cleanUrl } from "../utils/index.js";
class NoticeClient extends BaseClient {
  /**
   * Creates a new notice client
   * @param options - The client options
   */
  constructor(options) {
    super({
      ...options,
      path: cleanUrl(options.path + "/notice")
    });
    this.options = options;
  }
  /**
   * Deserializes a notice from the server response
   * Converts date strings to Date objects
   * @param notice - The serialized notice data
   * @returns The deserialized notice with proper date objects
   */
  deserialize(notice) {
    return {
      ...notice,
      createdAt: NoticeClient.deserializeDate(notice.createdAt),
      updatedAt: NoticeClient.deserializeDate(notice.updatedAt),
      starts: NoticeClient.deserializeDate(notice.starts),
      expires: NoticeClient.deserializeDate(notice.expires)
    };
  }
  /**
   * Lists all notices with optional filtering
   * @param query - Optional query parameters for filtering notices
   * @returns A paginated list of notices
   */
  async list(query = {}) {
    const { data } = await this.fetch.get(
      "",
      query
    );
    return {
      ...data,
      items: data.items.map((notice) => this.deserialize(notice))
    };
  }
  /**
   * Gets a single notice by ID
   * @param id - The ID of the notice to retrieve
   * @returns The notice data
   */
  async get(id) {
    const { data } = await this.fetch.get(`/${id}`);
    return this.deserialize(data);
  }
  /**
   * Creates a new notice
   * @param data - The notice data to create
   * @returns The created notice
   */
  async create(data) {
    const { data: responseData } = await this.fetch.post(
      "",
      data
    );
    return this.deserialize(responseData);
  }
  /**
   * Updates an existing notice
   * @param id - The ID of the notice to update
   * @param data - The update data
   * @returns The updated notice
   */
  async update(id, data) {
    const { data: responseData } = await this.fetch.patch(`/${id}`, data);
    return this.deserialize(responseData);
  }
  /**
   * Deletes a notice
   * @param id - The ID of the notice to delete
   */
  async delete(id) {
    await this.fetch.delete(`/${id}`);
  }
}
export {
  NoticeClient
};
