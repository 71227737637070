import { BaseClient } from "./base.client.js";
import { cleanUrl } from "../utils/index.js";
class EmailClient extends BaseClient {
  /**
   * Creates a new email client
   * @param options - The client options
   */
  constructor(options) {
    super({
      ...options,
      path: cleanUrl(options.path + "/email")
    });
    this.options = options;
  }
  /**
   * Retrieves email data by ID
   * @param id - The email ID to fetch
   * @returns The email data
   */
  async get(id) {
    const { data } = await this.fetch.get(`/${id}`);
    return data;
  }
  /**
   * Updates an existing email
   * @param id - The email ID to update
   * @param data - The update data for the email
   * @returns The updated email data
   */
  async update(id, data) {
    const { data: responseData } = await this.fetch.put(
      `/${id}`,
      data
    );
    return responseData;
  }
}
export {
  EmailClient
};
