var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import {
  cleanUrl,
  ClientApiError,
  isJson,
  hasProtocol,
  CookiePolyfill,
  queryStringify
} from "./index.js";
class Fetch {
  constructor(options = {}) {
    __publicField(this, "options");
    __publicField(this, "errorHandlers", []);
    /**
     * Header name value pair to send on each request
     */
    __publicField(this, "_requestHeadersEachRequest", {});
    if (options.token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${options.token}`
      };
      options.credentials || (options.credentials = "same-origin");
    } else {
      options.credentials || (options.credentials = "include");
    }
    options.dataType || (options.dataType = "json");
    options.cache || (options.cache = "default");
    options.method || (options.method = "GET");
    options.mode || (options.mode = "cors");
    options.isAjax || (options.isAjax = typeof options.isAjax === "boolean" ? options.isAjax : true);
    options.basePath || (options.basePath = "/");
    this.options = options;
  }
  /**
   * Load data from the server using a HTTP POST request.
   * @param url A string containing the URL to which the request is sent.
   * @param data The data to be sent in the request body
   * @param options Additional options for the request
   * @param options.params Object containing URL query parameters. These are always converted to URL query parameters,
   *                      while data goes into the request body.
   */
  post(url, data, options = {}) {
    return this.fetch(url, "POST", data, options);
  }
  /**
   * Delete a resource on the server using a HTTP DELETE request.
   * @param url A string containing the URL to which the request is sent.
   * @param data The data to be sent in the request body
   * @param options Additional options for the request
   * @param options.params Object containing URL query parameters. These are always converted to URL query parameters,
   *                      while data goes into the request body.
   */
  delete(url, data, options = {}) {
    return this.fetch(url, "DELETE", data, options);
  }
  /**
   * Update a resource on the server using a HTTP PUT request.
   * Use this when you want to replace an entire resource.
   * @param url A string containing the URL to which the request is sent.
   * @param data The data to be sent in the request body
   * @param options Additional options for the request
   * @param options.params Object containing URL query parameters. These are always converted to URL query parameters,
   *                      while data goes into the request body.
   */
  put(url, data, options = {}) {
    return this.fetch(url, "PUT", data, options);
  }
  /**
   * Update a resource on the server using a HTTP PATCH request.
   * Use this when you want to apply partial modifications to a resource.
   * @param url A string containing the URL to which the request is sent.
   * @param data The data to be sent in the request body
   * @param options Additional options for the request
   * @param options.params Object containing URL query parameters. These are always converted to URL query parameters,
   *                      while data goes into the request body.
   */
  patch(url, data, options = {}) {
    return this.fetch(url, "PATCH", data, options);
  }
  /**
   * Load data from the server using a HTTP GET request.
   * @param url A string containing the URL to which the request is sent.
   * @param data For GET requests, this will be converted to URL query parameters along with options.params.
   *             This is useful for backward compatibility and convenience, since GET requests cannot have a body.
   * @param options Additional options for the request
   * @param options.params Object containing URL query parameters. These are always converted to URL query parameters
   *                      regardless of the HTTP method. For non-GET requests, only options.params are added to the URL,
   *                      while data goes into the request body.
   */
  get(url, data, options = {}) {
    return this.fetch(url, "GET", data, options);
  }
  /**
   * Parse the dataType to headers
   * @param dataType The type of data expected from the server. Default: Intelligent Guess (xml, json, script, text, html).
   */
  parseDataType(dataType) {
    const headers = {};
    let contentType = void 0;
    let accept = "*/*";
    switch (dataType) {
      case "script":
        contentType = "application/javascript";
        break;
      case "json":
        contentType = "application/json";
        accept = "application/json";
        break;
      case "xml":
        contentType = "application/xml";
        accept = "application/xml, text/xml";
        break;
      case "text":
        contentType = "text/plain";
        accept = "text/plain";
        break;
      case "html":
        contentType = "text/html";
        accept = "text/html";
        break;
      case "form":
      case "multipart":
        contentType = void 0;
        break;
    }
    if (contentType) {
      headers["Content-Type"] = contentType;
    }
    if (accept) {
      headers["Accept"] = accept;
    }
    return headers;
  }
  /**
   * Add a global error handler
   * @param handler Function to handle errors
   */
  onError(handler) {
    this.errorHandlers.push(handler);
  }
  /**
   * Core fetch method that handles all HTTP requests
   * @param url The URL to which the request is sent
   * @param method The HTTP method to use (GET, POST, PUT, DELETE, etc.)
   * @param data The data to be processed:
   *        - For GET requests: Converted to URL parameters along with options.params
   *          since GET requests cannot have a body
   *        - For other methods: Sent as request body, while only options.params
   *          are converted to URL parameters
   * @param options Additional options for the request
   * @param options.params Object containing URL query parameters. These are always
   *                      converted to URL parameters regardless of HTTP method.
   *                      This separation allows for consistent URL parameter handling
   *                      across all HTTP methods.
   * @returns Promise resolving to the fetch response with parsed data
   * @template T Type of the expected response data
   * @template D Type of the request data
   */
  async fetch(url, method = "GET", data = {}, options = {}) {
    try {
      return await this.performFetch(url, method, data, options);
    } catch (error) {
      if (error instanceof ClientApiError) {
        this.errorHandlers.forEach((handler) => handler(error));
      }
      throw error;
    }
  }
  // Rename existing fetch implementation to performFetch
  async performFetch(url, method = "GET", data = {}, options = {}) {
    if (!fetch) {
      throw new Error(
        "Your platform does not support the fetch API, please install a polyfill."
      );
    }
    options = { ...this.options, ...options };
    options.params || (options.params = {});
    if (typeof url === "string" && !hasProtocol(url)) {
      url = cleanUrl(options.basePath + "/" + url);
    }
    url = new URL(url, this.options.host);
    const headers = {
      ...this.options.headers,
      ...options.headers,
      ...this.parseDataType(options.dataType)
    };
    if (CookiePolyfill.shouldBeUsed(options.credentials)) {
      const cookies = CookiePolyfill.get();
      if (cookies.length > 0) {
        headers["Cookie"] = cookies.join("; ");
      }
    }
    if (options.isAjax && !headers["X-Requested-With"]) {
      headers["X-Requested-With"] = "XMLHttpRequest";
    }
    let body = options.body;
    if (method === "GET") {
      const queryParams = {
        ...options.params,
        ...data
      };
      if (Object.keys(queryParams).length > 0) {
        url.search = queryStringify(queryParams);
      }
    } else {
      if (Object.keys(options.params).length > 0) {
        url.search = queryStringify(options.params);
      }
      if (data) {
        if (options.dataType === "form" || options.dataType === "multipart") {
          if (data instanceof FormData) {
            body = data;
          } else {
            body = new URLSearchParams(data);
          }
        } else {
          body = JSON.stringify(data);
        }
      }
    }
    const response = await globalThis.fetch(url, {
      ...options,
      method,
      body,
      headers
    });
    if (CookiePolyfill.shouldBeUsed(options.credentials)) {
      const setCookieHeaders = response.headers.getSetCookie();
      if (setCookieHeaders?.length > 0) {
        CookiePolyfill.store(setCookieHeaders);
      }
    }
    let bodyResult = await response.text();
    if (typeof bodyResult === "string" && isJson(bodyResult)) {
      bodyResult = JSON.parse(bodyResult);
    }
    if (typeof bodyResult === "string") {
      switch (bodyResult) {
        case "null":
        case "":
          bodyResult = null;
          break;
        case "true":
          bodyResult = true;
          break;
        case "false":
          bodyResult = false;
          break;
        case "undefined":
          bodyResult = void 0;
          break;
      }
    }
    const result = {
      ...response,
      data: bodyResult,
      ok: response.status >= 200 && response.status < 400
    };
    if (!result.ok) {
      if (result.data) {
        const data2 = result.data;
        console.error("Error response", data2);
        if (Array.isArray(data2.errors)) {
          for (const error of data2.errors) {
            console.error(JSON.stringify(error, null, 2));
          }
        }
        throw new ClientApiError(data2.message || "Unknown error", {
          ...data2,
          status: response.status
        });
      }
      throw result;
    }
    return result;
  }
  /**
   * Clear stored cookies
   */
  clearCookies() {
    if (CookiePolyfill.shouldBeUsed(this.options.credentials)) {
      CookiePolyfill.clear();
    }
  }
}
export {
  Fetch
};
