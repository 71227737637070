import { PaymentStatus } from "../data/index.js";
const paymentFilters = {
  id: {
    type: "text"
  },
  contact: {
    type: "contact"
  },
  chargeDate: {
    type: "date"
  },
  amount: {
    type: "number"
  },
  status: {
    type: "enum",
    options: [
      PaymentStatus.Draft,
      PaymentStatus.Pending,
      PaymentStatus.Successful,
      PaymentStatus.Failed,
      PaymentStatus.Cancelled
    ]
  }
};
paymentFilters;
export {
  paymentFilters
};
