var PaymentStatus = /* @__PURE__ */ ((PaymentStatus2) => {
  PaymentStatus2["Draft"] = "draft";
  PaymentStatus2["Pending"] = "pending";
  PaymentStatus2["Successful"] = "successful";
  PaymentStatus2["Failed"] = "failed";
  PaymentStatus2["Cancelled"] = "cancelled";
  return PaymentStatus2;
})(PaymentStatus || {});
export {
  PaymentStatus
};
