const cleanUrl = (url) => {
  return url.replaceAll("//", "/");
};
const hasProtocol = (url) => {
  return Boolean(url.match(/^[a-z]+:\/\//));
};
const queryStringify = (obj) => {
  const params = new URLSearchParams();
  Object.entries(obj).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => {
        params.append(key, item);
      });
    } else if (value instanceof Date) {
      params.append(key, value.toISOString());
    } else if (typeof value === "object") {
      params.append(key, JSON.stringify(value));
    } else if (value !== void 0) {
      params.append(key, value.toString());
    }
  });
  return params.toString();
};
export {
  cleanUrl,
  hasProtocol,
  queryStringify
};
