import { isNumber, isNumberInRange } from "../utils/index.js";
const calloutComponentInputNumberValidator = (schema, answer) => {
  if (schema.validate && !isNumberInRange(answer, schema.validate.min, schema.validate.max)) {
    return false;
  }
  return isNumber(answer);
};
export {
  calloutComponentInputNumberValidator
};
