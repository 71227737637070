var CalloutComponentType = /* @__PURE__ */ ((CalloutComponentType2) => {
  CalloutComponentType2["CONTENT"] = "content";
  CalloutComponentType2["INPUT_ADDRESS"] = "address";
  CalloutComponentType2["INPUT_CHECKBOX"] = "checkbox";
  CalloutComponentType2["INPUT_CURRENCY"] = "currency";
  CalloutComponentType2["INPUT_DATE_TIME"] = "datetime";
  CalloutComponentType2["INPUT_EMAIL"] = "email";
  CalloutComponentType2["INPUT_FILE"] = "file";
  CalloutComponentType2["INPUT_NUMBER"] = "number";
  CalloutComponentType2["INPUT_PHONE_NUMBER"] = "phoneNumber";
  CalloutComponentType2["INPUT_SELECT"] = "select";
  CalloutComponentType2["INPUT_SELECTABLE_RADIO"] = "radio";
  CalloutComponentType2["INPUT_SELECTABLE_SELECTBOXES"] = "selectboxes";
  CalloutComponentType2["INPUT_SIGNATURE"] = "signature";
  CalloutComponentType2["INPUT_TEXT_AREA"] = "textarea";
  CalloutComponentType2["INPUT_TEXT_FIELD"] = "textfield";
  CalloutComponentType2["INPUT_TIME"] = "time";
  CalloutComponentType2["INPUT_URL"] = "url";
  CalloutComponentType2["NESTABLE_PANEL"] = "panel";
  CalloutComponentType2["NESTABLE_TABS"] = "tabs";
  CalloutComponentType2["NESTABLE_WELL"] = "well";
  return CalloutComponentType2;
})(CalloutComponentType || {});
export {
  CalloutComponentType
};
