var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import {
  ContributionPeriod
} from "@beabee/beabee-common";
import { BaseClient } from "./base.client.js";
import { cleanUrl } from "../utils/index.js";
class SignupClient extends BaseClient {
  /**
   * Creates a new signup client
   * @param options - The client options
   */
  constructor(options) {
    super({
      ...options,
      path: cleanUrl(options.path + "/signup")
    });
    this.options = options;
    /**
     * The URL to complete the signup process
     */
    __publicField(this, "completeUrl");
    this.completeUrl = this.options.host + "/join/complete";
  }
  /**
   * Initiates the signup process for a new user
   * @param data - The signup data including email and contribution details
   * @returns Payment flow parameters for completing signup
   */
  async start(data) {
    const { data: responseData } = await this.fetch.post("", {
      email: data.email,
      loginUrl: this.options.host + "/auth/login",
      setPasswordUrl: this.options.host + "/auth/set-password",
      confirmUrl: this.options.host + "/join/confirm-email",
      ...!data.noContribution && {
        contribution: {
          amount: data.amount,
          period: data.period,
          payFee: data.payFee && data.period === ContributionPeriod.Monthly,
          prorate: false,
          paymentMethod: data.paymentMethod,
          completeUrl: this.completeUrl
        }
      }
    });
    return responseData;
  }
  /**
   * Completes the signup process with user details
   * @param data - The completion data including name and payment details
   */
  async complete(data) {
    await this.fetch.post("/complete", {
      paymentFlowId: data.paymentFlowId,
      firstname: data.firstname,
      lastname: data.lastname,
      vatNumber: data.vatNumber
    });
  }
  /**
   * Confirms a user's email address
   * @param joinFlowId - The join flow ID from the confirmation email
   */
  async confirmEmail(joinFlowId) {
    await this.fetch.post("/confirm-email", { joinFlowId });
  }
}
export {
  SignupClient
};
