const maybeJsonString = (str) => {
  if (!str || typeof str !== "string") {
    return false;
  }
  str = str.trim();
  return str.charAt(0) === "{" && str.charAt(str.length - 1) === "}" || str.charAt(0) === "[" && str.charAt(str.length - 1) === "]";
};
const isJson = (str) => {
  if (!maybeJsonString(str)) {
    return false;
  }
  try {
    const val = JSON.parse(str);
    return Array.isArray(val) || typeof val === "object" ? true : false;
  } catch (_) {
    return false;
  }
};
export {
  isJson,
  maybeJsonString
};
