var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import { BaseClient } from "./base.client.js";
import { cleanUrl } from "../utils/index.js";
import {
  ContributionPeriod
} from "@beabee/beabee-common";
class ContactContributionClient extends BaseClient {
  constructor(options) {
    super({
      ...options,
      path: cleanUrl(options.path + "/contact")
    });
    this.options = options;
    __publicField(this, "completeUrl");
    this.completeUrl = options.host + "/profile/contribution/complete";
  }
  /**
   * Deserialize a contribution
   */
  static deserialize(data) {
    return {
      ...data,
      cancellationDate: this.deserializeDate(data.cancellationDate),
      membershipExpiryDate: this.deserializeDate(data.membershipExpiryDate),
      renewalDate: this.deserializeDate(data.renewalDate)
    };
  }
  /**
   * Get the current user's contribution information
   */
  async get() {
    const { data } = await this.fetch.get("/me/contribution");
    return ContactContributionClient.deserialize(data);
  }
  /**
   * Update the current user's contribution
   */
  async update(updateData) {
    const { data } = await this.fetch.patch(
      "/me/contribution",
      {
        amount: updateData.amount,
        period: updateData.period,
        payFee: updateData.payFee && updateData.period === ContributionPeriod.Monthly,
        prorate: updateData.prorate && updateData.period === ContributionPeriod.Annually
      }
    );
    return ContactContributionClient.deserialize(data);
  }
  /**
   * Force update a contribution for a specific contact
   */
  async forceUpdate(id, updateData) {
    const { data } = await this.fetch.patch(
      `/${id}/contribution/force`,
      updateData
    );
    return ContactContributionClient.deserialize(data);
  }
  /**
   * Start a new contribution
   */
  async start(startData) {
    const { data } = await this.fetch.post("/me/contribution", {
      amount: startData.amount,
      period: startData.period,
      payFee: startData.payFee && startData.period === ContributionPeriod.Monthly,
      prorate: startData.prorate && startData.period === ContributionPeriod.Annually,
      paymentMethod: startData.paymentMethod,
      completeUrl: this.completeUrl
    });
    return data;
  }
  /**
   * Complete starting a contribution
   */
  async completeStart(paymentFlowId) {
    const { data } = await this.fetch.post(
      "/me/contribution/complete",
      {
        paymentFlowId
      }
    );
    return ContactContributionClient.deserialize(data);
  }
  /**
   * Cancel a contribution
   */
  async cancel(id) {
    await this.fetch.post(`/${id}/contribution/cancel`);
  }
}
export {
  ContactContributionClient
};
