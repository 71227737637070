import {
  CalloutComponentBaseType,
  calloutComponentInputSelectableTypes,
  calloutComponentInputTextTypes,
  calloutComponentInputTypes,
  calloutComponentNestableTypes,
  CalloutComponentType
} from "../data/index.js";
function convertValuesToOptions(values) {
  return values.map(({ value }) => value);
}
function convertComponentToFilter(component) {
  const baseItem = {
    label: component.label || component.fullKey,
    nullable: true
  };
  if (isCalloutComponentOfType(component, CalloutComponentType.INPUT_CHECKBOX)) {
    return { ...baseItem, type: "boolean", nullable: false };
  }
  if (isCalloutComponentOfType(component, CalloutComponentType.INPUT_NUMBER)) {
    return { ...baseItem, type: "number" };
  }
  if (isCalloutComponentOfType(component, CalloutComponentType.INPUT_SELECT)) {
    return {
      ...baseItem,
      type: "enum",
      options: convertValuesToOptions(component.data.values)
    };
  }
  if (isCalloutComponentOfBaseType(
    component,
    CalloutComponentBaseType.INPUT_SELECTABLE
  )) {
    return {
      ...baseItem,
      type: component.type === CalloutComponentType.INPUT_SELECTABLE_RADIO ? "enum" : "array",
      options: convertValuesToOptions(component.values)
    };
  }
  if (isCalloutComponentOfType(component, CalloutComponentType.INPUT_TEXT_AREA)) {
    return { ...baseItem, type: "blob" };
  }
  return { ...baseItem, type: "text" };
}
function getSelectableLabelFromValue(component, value) {
  if (isCalloutComponentOfBaseType(
    component,
    CalloutComponentBaseType.INPUT_SELECTABLE
  )) {
    return component.values.find((v) => v.value === value)?.label || value;
  }
  if (isCalloutComponentOfType(component, CalloutComponentType.INPUT_SELECT)) {
    return component.data.values.find((v) => v.value === value)?.label || value;
  }
  return value;
}
function isCalloutComponentOfType(component, type) {
  return "type" in component && component.type === type;
}
function isCalloutComponentOfBaseType(component, type) {
  if (!("type" in component)) {
    return false;
  }
  if (type === CalloutComponentBaseType.CONTENT) {
    return isCalloutComponentOfType(component, CalloutComponentType.CONTENT);
  }
  if (type === CalloutComponentBaseType.INPUT) {
    return calloutComponentInputTypes.includes(component.type);
  }
  if (type === CalloutComponentBaseType.INPUT_TEXT) {
    return calloutComponentInputTextTypes.includes(
      component.type
    );
  }
  if (type === CalloutComponentBaseType.INPUT_SELECTABLE) {
    return calloutComponentInputSelectableTypes.includes(
      component.type
    );
  }
  if (type === CalloutComponentBaseType.NESTABLE) {
    return calloutComponentNestableTypes.includes(component.type);
  }
  return false;
}
function flattenComponents(components) {
  return components.flatMap(
    (component) => isCalloutComponentOfBaseType(component, CalloutComponentBaseType.NESTABLE) ? [component, ...flattenComponents(component.components)] : [component]
  );
}
function filterComponents(components, filterFn) {
  return components.filter(filterFn).map((component) => {
    return {
      ...component,
      ...isCalloutComponentOfBaseType(
        component,
        CalloutComponentBaseType.NESTABLE
      ) && {
        components: filterComponents(component.components, filterFn)
      }
    };
  });
}
function getCalloutComponents(formSchema) {
  return formSchema.slides.flatMap(
    (slide) => flattenComponents(slide.components).map((component) => ({
      ...component,
      slideId: slide.id,
      fullKey: `${slide.id}.${component.key}`
    }))
  );
}
function getCalloutFilters(formSchema) {
  const items = getCalloutComponents(formSchema).filter((c) => c.input).map((c) => [`answers.${c.fullKey}`, convertComponentToFilter(c)]);
  return Object.fromEntries(items);
}
function isAddressAnswer(answer) {
  return !!answer && typeof answer === "object" && "geometry" in answer;
}
function isFileUploadAnswer(answer) {
  return !!answer && typeof answer === "object" && "url" in answer;
}
function stringifyAnswer(component, answer) {
  if (Array.isArray(answer)) {
    return answer.map((a) => stringifyAnswer(component, a)).join(", ");
  } else if (!answer) {
    return "";
  } else if (isAddressAnswer(answer)) {
    return answer.geometry.location.lat + ", " + answer.geometry.location.lng;
  } else if (isFileUploadAnswer(answer)) {
    return answer.url;
  } else if (typeof answer === "object") {
    return Object.entries(answer).filter(([, selected]) => selected).map(([value]) => getSelectableLabelFromValue(component, value)).join(", ");
  } else if (typeof answer === "string") {
    return getSelectableLabelFromValue(component, answer);
  } else {
    return answer.toString();
  }
}
export {
  filterComponents,
  flattenComponents,
  getCalloutComponents,
  getCalloutFilters,
  isAddressAnswer,
  isCalloutComponentOfBaseType,
  isCalloutComponentOfType,
  isFileUploadAnswer,
  stringifyAnswer
};
