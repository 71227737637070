var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import { BaseClient } from "./base.client.js";
import { cleanUrl } from "../utils/index.js";
import { ContactContributionClient } from "./contact-contribution.client.js";
class ContactPaymentClient extends BaseClient {
  /**
   * Creates a new contact payment client
   * @param options - The client options
   */
  constructor(options) {
    super({
      ...options,
      path: cleanUrl(options.path + "/contact")
    });
    this.options = options;
    __publicField(this, "completeUrl");
    this.completeUrl = options.host + "/profile/contribution/payment-method/complete";
  }
  /**
   * Updates a contact's payment method
   * Initiates a payment flow for setting up a new payment method
   * @param paymentMethod - The payment method identifier, or undefined to remove
   * @returns Payment flow parameters for client-side handling
   */
  async update(paymentMethod) {
    const { data } = await this.fetch.put("/me/payment-method", {
      paymentMethod,
      completeUrl: this.completeUrl
    });
    return data;
  }
  /**
   * Completes a payment method update flow
   * Called after the payment provider redirects back to the application
   * @param paymentFlowId - The ID of the payment flow to complete
   * @returns Updated contribution information
   */
  async completeUpdate(paymentFlowId) {
    const { data } = await this.fetch.post("/me/payment-method/complete", {
      paymentFlowId
    });
    return ContactContributionClient.deserialize(data);
  }
  /**
   * Lists payment history for a contact
   * Includes payment status, amounts, and dates
   * @param id - The contact ID
   * @param query - Query parameters for filtering payments
   * @returns Paginated list of payment data
   */
  async list(id, query = {}) {
    const { data } = await this.fetch.get(
      `/${id}/payment`,
      query
    );
    return {
      ...data,
      items: data.items.map((item) => ({
        chargeDate: ContactPaymentClient.deserializeDate(item.chargeDate),
        amount: item.amount,
        status: item.status
      }))
    };
  }
}
export {
  ContactPaymentClient
};
