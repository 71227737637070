import { BaseClient } from "./base.client.js";
import { cleanUrl } from "../utils/index.js";
class ApiKeyClient extends BaseClient {
  /**
   * Creates a new API key client
   * @param options - The client options
   */
  constructor(options) {
    super({
      ...options,
      path: cleanUrl(options.path + "/api-key")
    });
    this.options = options;
  }
  /**
   * Deserializes an API key from the server response
   * @param apiKey - The serialized API key data
   * @returns The deserialized API key data
   */
  static deserialize(apiKey) {
    return {
      ...apiKey,
      createdAt: this.deserializeDate(apiKey.createdAt),
      expires: this.deserializeDate(apiKey.expires)
    };
  }
  /**
   * Creates a new API key
   * @param newData - The data for creating the new API key
   * @returns The created API key
   */
  async create(newData) {
    const { data } = await this.fetch.post(
      "",
      newData
    );
    return data;
  }
  /**
   * Lists all API keys with optional filtering
   * @param query - Optional query parameters for filtering the list
   * @returns A paginated list of API keys
   */
  async list(query) {
    const { data } = await this.fetch.get(
      "",
      query
    );
    return {
      ...data,
      items: data.items.map((item) => ApiKeyClient.deserialize(item))
    };
  }
  /**
   * Deletes an API key
   * @param id - The ID of the API key to delete
   */
  async delete(id) {
    await this.fetch.delete(`/${id}`);
  }
}
export {
  ApiKeyClient
};
