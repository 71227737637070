var PaymentMethod = /* @__PURE__ */ ((PaymentMethod2) => {
  PaymentMethod2["StripeCard"] = "s_card";
  PaymentMethod2["StripeSEPA"] = "s_sepa";
  PaymentMethod2["StripeBACS"] = "s_bacs";
  PaymentMethod2["StripePayPal"] = "s_paypal";
  PaymentMethod2["StripeIdeal"] = "s_ideal";
  PaymentMethod2["GoCardlessDirectDebit"] = "gc_direct-debit";
  return PaymentMethod2;
})(PaymentMethod || {});
export {
  PaymentMethod
};
