import { BaseClient } from "./base.client.js";
import { cleanUrl } from "../utils/index.js";
class TagClient extends BaseClient {
  constructor(options) {
    super({
      ...options,
      path: cleanUrl(options.path ?? "")
    });
    this.options = options;
  }
  /**
   * Fetch all tags
   * @param entityId Optional entity ID to filter tags
   * @returns List of tags
   */
  async list(entityId) {
    const { data } = await this.fetch.get(
      this.getBasePath(entityId),
      {
        sort: "name",
        order: "ASC"
      }
    );
    return data;
  }
  /**
   * Create a new tag
   * @param entityId Optional entity ID
   * @param tagData The tag data to create
   * @returns The created tag
   */
  async create(entityId, tagData) {
    const { data } = await this.fetch.post(
      this.getBasePath(entityId),
      tagData
    );
    return data;
  }
  /**
   * Update an existing tag
   * @param entityId Optional entity ID
   * @param tagId The ID of the tag to update
   * @param tagData The updated tag data
   * @returns The updated tag
   */
  async update(entityId, tagId, tagData) {
    const { data } = await this.fetch.patch(
      `${this.getBasePath(entityId)}/${tagId}`,
      tagData
    );
    return data;
  }
  /**
   * Delete a tag
   * @param entityId Optional entity ID
   * @param tagId The ID of the tag to delete
   */
  async delete(entityId, tagId) {
    await this.fetch.delete(`${this.getBasePath(entityId)}/${tagId}`);
  }
}
export {
  TagClient
};
