var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import { BaseClient } from "./base.client.js";
import { CalloutClient } from "./callout.client.js";
import { ContentClient } from "./content.client.js";
import { ContactClient } from "./contact.client.js";
import { ApiKeyClient } from "./api-key.client.js";
import { AuthClient } from "./auth.client.js";
import { EmailClient } from "./email.client.js";
import { NoticeClient } from "./notice.client.js";
import { PaymentClient } from "./payment.client.js";
import { ResetSecurityClient } from "./reset-security.client.js";
import { SegmentsClient } from "./segments.client.js";
import { SignupClient } from "./signup.client.js";
import { StatsClient } from "./stats.client.js";
import { UploadClient } from "./upload.client.js";
class BeabeeClient extends BaseClient {
  /**
   * Creates a new Beabee API client instance
   * @param options - Configuration options for the client
   * @param options.host - API host URL
   * @param options.token - Authentication token
   * @param options.path - Optional base path for API endpoints (defaults to /api/1.0)
   */
  constructor(options) {
    super(options);
    this.options = options;
    /** Client for managing content pages and blocks */
    __publicField(this, "content");
    /** Client for managing callouts and their responses */
    __publicField(this, "callout");
    /** Client for managing contacts (users) */
    __publicField(this, "contact");
    /** Client for managing API keys */
    __publicField(this, "apiKey");
    /** Client for managing authentication */
    __publicField(this, "auth");
    /** Client for managing emails */
    __publicField(this, "email");
    /** Client for managing system notices */
    __publicField(this, "notice");
    /** Client for managing payments */
    __publicField(this, "payment");
    /** Client for managing security reset flows */
    __publicField(this, "resetSecurity");
    /** Client for managing segments */
    __publicField(this, "segments");
    /** Client for managing signup */
    __publicField(this, "signup");
    /** Client for fetching statistics */
    __publicField(this, "stats");
    /** Client for managing file uploads */
    __publicField(this, "upload");
    this.content = new ContentClient(options);
    this.callout = new CalloutClient(options);
    this.contact = new ContactClient(options);
    this.apiKey = new ApiKeyClient(options);
    this.auth = new AuthClient(options);
    this.email = new EmailClient(options);
    this.notice = new NoticeClient(options);
    this.payment = new PaymentClient(options);
    this.resetSecurity = new ResetSecurityClient(options);
    this.segments = new SegmentsClient(options);
    this.signup = new SignupClient(options);
    this.stats = new StatsClient(options);
    this.upload = new UploadClient(options);
  }
}
export {
  BeabeeClient
};
