import { BaseClient } from "./base.client.js";
import { cleanUrl } from "../utils/index.js";
class SegmentsClient extends BaseClient {
  /**
   * Creates a new segments client
   * @param options - The client options
   */
  constructor(options) {
    super({
      ...options,
      path: cleanUrl(options.path + "/segments")
    });
    this.options = options;
  }
  /**
   * Retrieves a list of all segments
   * @param query - Optional query parameters to filter segments
   * @param _with - Optional related data to include with each segment
   * @returns Array of segments matching the query
   */
  async list(query = {}, _with) {
    const { data } = await this.fetch.get(
      "",
      { with: _with, ...query }
    );
    return data;
  }
  /**
   * Retrieves a single segment by ID
   * @param id - The segment ID
   * @param _with - Optional related data to include with the segment
   * @returns The requested segment
   */
  async get(id, _with) {
    const { data } = await this.fetch.get(
      `/${id}`,
      { with: _with }
    );
    return data;
  }
  /**
   * Creates a new segment
   * @param input - The segment data including name and rule group
   * @returns The created segment with contact count
   *
   * @example
   * ```typescript
   * await segments.create({
   *   name: "Active Users",
   *   ruleGroup: {
   *     type: "group",
   *     rules: [
   *       // Define filter rules here
   *     ]
   *   }
   * });
   * ```
   */
  async create(input) {
    const { data } = await this.fetch.post("", {
      name: input.name,
      order: input.order,
      ruleGroup: input.ruleGroup,
      description: ""
      // TODO: deprecated from API
    });
    return data;
  }
  /**
   * Updates an existing segment
   * @param id - The segment ID to update
   * @param input - The updated segment data
   * @returns The updated segment with contact count
   *
   * @example
   * ```typescript
   * await segments.update("segment-id", {
   *   name: "Updated Segment Name",
   *   ruleGroup: {
   *     // Updated filter rules
   *   }
   * });
   * ```
   */
  async update(id, input) {
    const { data } = await this.fetch.patch(`/${id}`, {
      name: input.name,
      order: input.order,
      ruleGroup: input.ruleGroup
    });
    return data;
  }
  /**
   * Deletes a segment
   * @param id - The segment ID to delete
   */
  async delete(id) {
    await this.fetch.delete(`/${id}`);
  }
}
export {
  SegmentsClient
};
