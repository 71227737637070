var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import { BaseClient } from "./base.client.js";
import { cleanUrl } from "../utils/index.js";
import { CalloutResponseClient } from "./callout-response.client.js";
import { CalloutTagClient } from "./callout-tag.client.js";
import { CalloutReviewerClient } from "./callout-reviewer.client.js";
class CalloutClient extends BaseClient {
  constructor(options) {
    super({
      ...options,
      path: cleanUrl(options.path + "/callout")
    });
    this.options = options;
    /** Client for managing callout responses */
    __publicField(this, "response");
    /** Client for managing callout tags */
    __publicField(this, "tag");
    /** Client for managing callout reviewers */
    __publicField(this, "reviewer");
    this.response = new CalloutResponseClient(options);
    this.tag = new CalloutTagClient(options);
    this.reviewer = new CalloutReviewerClient(options);
  }
  /**
   * Deserialize a callout
   * @param callout The callout to deserialize
   * @returns The deserialized callout
   */
  static deserialize(_callout) {
    const callout = {
      ..._callout,
      starts: CalloutClient.deserializeDate(_callout.starts),
      expires: CalloutClient.deserializeDate(_callout.expires)
    };
    return callout;
  }
  /**
   * Get a callout
   * @param slugOrId The slug or id of the callout to get
   * @param _with The relations to include
   * @returns The callout
   */
  async get(slugOrId, _with, variant) {
    const { data } = await this.fetch.get(
      `/${slugOrId}`,
      { with: _with, variant }
    );
    return CalloutClient.deserialize(data);
  }
  /**
   * List callouts
   * @param _with The relations to include
   * @returns A paginated list of callouts
   */
  async list(query = {}, _with) {
    const { data } = await this.fetch.get("/", { with: _with, ...query });
    const items = data.items.map((item) => CalloutClient.deserialize(item));
    const callouts = {
      ...data,
      items
    };
    return callouts;
  }
  /**
   * Create a callout
   * @param newData The data to create the callout with
   * @returns The created callout
   */
  async create(newData) {
    const { data } = await this.fetch.post(
      "/",
      newData
    );
    return data;
  }
  /**
   * Update a callout
   * @param slug The slug of the callout to update
   * @param updateData The data to update
   * @returns The updated callout
   */
  async update(slug, updateData) {
    const { data } = await this.fetch.patch(
      "/" + slug,
      updateData
    );
    return CalloutClient.deserialize(data);
  }
  /**
   * Delete a callout
   * @param slug The slug of the callout to delete
   */
  async delete(slug) {
    await this.fetch.delete("/" + slug);
  }
  /**
   * List responses for a callout
   * @param slug The slug of the callout
   * @param query Optional query parameters
   * @param _with Optional relations to include
   */
  async listResponses(slug, query, _with) {
    const { data } = await this.fetch.get(`/${slug}/responses`, { with: _with, ...query });
    return {
      ...data,
      items: data.items.map((item) => CalloutResponseClient.deserialize(item))
    };
  }
  /**
   * Get responses for map visualization
   * @param slug The slug of the callout
   * @param query Optional query parameters
   */
  async listResponsesForMap(slug, query) {
    const { data } = await this.fetch.get(`/${slug}/responses/map`, query);
    return data;
  }
  /**
   * Create a callout response
   * @param slug The slug of the callout
   * @param newData The response data
   * @param captchaToken Optional captcha token
   */
  async createResponse(slug, newData, captchaToken) {
    const { data } = await this.fetch.post(
      `/${slug}/responses`,
      {
        answers: newData.answers,
        guestName: newData.guestName,
        guestEmail: newData.guestEmail
      },
      { params: { captchaToken } }
    );
    return CalloutResponseClient.deserialize(data);
  }
  /**
   * Creates a new callout based on an existing one
   * @param fromId - ID of the callout to replicate from
   * @param updateData - Data to update in the new callout
   * @returns The created callout
   */
  async clone(fromId, updateData) {
    const { data } = await this.fetch.post(
      "",
      updateData,
      {
        params: { fromId }
      }
    );
    return CalloutClient.deserialize(data);
  }
}
export {
  CalloutClient
};
