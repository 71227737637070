import { BaseClient } from "./base.client.js";
import {
  RESET_SECURITY_FLOW_TYPE
} from "@beabee/beabee-common";
class ResetSecurityClient extends BaseClient {
  /**
   * Creates a new reset security client
   * @param options - The client options
   */
  constructor(options) {
    super(options);
    this.options = options;
  }
  /**
   * Starts the reset password flow
   * Used when a user has forgotten their password
   * @param email - The email of the user
   */
  async resetPasswordBegin(email) {
    const data = {
      email,
      resetUrl: this.options.host + "/auth/reset-password"
    };
    await this.fetch.post("reset-password", data);
  }
  /**
   * Completes the reset password flow
   * Used after the user clicks the link in their email
   * @param resetPasswordFlowId - The ID of the reset password flow
   * @param password - The new password
   * @param token - Optional verification token
   */
  async resetPasswordComplete(resetPasswordFlowId, password, token) {
    const data = {
      password,
      token
    };
    await this.fetch.put(`reset-password/${resetPasswordFlowId}`, data);
  }
  /**
   * Starts the reset device flow
   * Used when a user has lost access to their 2FA device
   * @param email - The email of the user
   */
  async resetDeviceBegin(email) {
    const data = {
      email,
      resetUrl: this.options.host + "/auth/reset-device",
      type: RESET_SECURITY_FLOW_TYPE.TOTP
    };
    await this.fetch.post("reset-device", data);
  }
  /**
   * Completes the reset device flow
   * Used after the user clicks the link in their email
   * @param resetMfaFlowId - The ID of the reset device flow
   * @param password - The user's current password for verification
   */
  async resetDeviceComplete(resetMfaFlowId, password) {
    const data = {
      password,
      type: RESET_SECURITY_FLOW_TYPE.TOTP
    };
    await this.fetch.put(`reset-device/${resetMfaFlowId}`, data);
  }
}
export {
  ResetSecurityClient
};
