var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
function getErrorNameFromStatus(status) {
  switch (status) {
    case 400:
      return "BadRequestError";
    case 401:
      return "UnauthorizedError";
    case 403:
      return "ForbiddenError";
    case 404:
      return "NotFoundError";
    case 409:
      return "ConflictError";
    case 422:
      return "ValidationError";
    case 429:
      return "TooManyRequestsError";
    case 500:
      return "InternalServerError";
    case 502:
      return "BadGatewayError";
    case 503:
      return "ServiceUnavailableError";
    case 504:
      return "GatewayTimeoutError";
    default:
      return status >= 500 ? "ServerError" : "ClientError";
  }
}
class ClientApiError extends Error {
  constructor(message, data = {}) {
    super(message);
    __publicField(this, "code");
    __publicField(this, "errors");
    __publicField(this, "httpCode");
    __publicField(this, "name");
    this.code = data.code;
    this.errors = data.errors;
    this.httpCode = data.httpCode;
    this.name = data.name || (this.httpCode ? getErrorNameFromStatus(this.httpCode) : "UnknownError");
  }
}
function isApiError(err, codes = [], status = [400]) {
  if (err instanceof ClientApiError) {
    const hasMatchingStatus = !status.length || !!err.httpCode && status.includes(err.httpCode);
    const hasMatchingCode = !codes.length || !!err.code && codes.includes(err.code);
    return hasMatchingStatus && hasMatchingCode;
  }
  return false;
}
export {
  ClientApiError,
  getErrorNameFromStatus,
  isApiError
};
