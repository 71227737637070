import { BaseClient } from "./base.client.js";
import { cleanUrl } from "../utils/index.js";
import { ContactClient } from "./contact.client.js";
class PaymentClient extends BaseClient {
  /**
   * Creates a new payment client
   * @param options - The client options
   */
  constructor(options) {
    super({
      ...options,
      path: cleanUrl(options.path + "/payment")
    });
    this.options = options;
  }
  /**
   * Deserializes a payment from the server response
   * @param payment - The serialized payment data
   * @returns The deserialized payment with proper date objects
   */
  static deserialize(payment) {
    return {
      ...payment,
      chargeDate: PaymentClient.deserializeDate(payment.chargeDate),
      amount: payment.amount,
      status: payment.status,
      ...payment.contact !== void 0 && {
        contact: payment.contact && ContactClient.deserialize(payment.contact)
      }
    };
  }
  /**
   * Lists payments with optional filtering
   * @param query - Query parameters for filtering payments
   * @param _with - Optional relations to include
   * @returns A paginated list of payments
   */
  async list(query = {}, _with) {
    const { data } = await this.fetch.get("", { with: _with, ...query });
    return {
      ...data,
      items: data.items.map((item) => PaymentClient.deserialize(item))
    };
  }
}
export {
  PaymentClient
};
