var ContributionType = /* @__PURE__ */ ((ContributionType2) => {
  ContributionType2["Automatic"] = "Automatic";
  ContributionType2["Manual"] = "Manual";
  ContributionType2["Gift"] = "Gift";
  ContributionType2["None"] = "None";
  return ContributionType2;
})(ContributionType || {});
export {
  ContributionType
};
