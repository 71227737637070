import { slug } from "./slug.js";
import { ContributionPeriod } from "../data/index.js";
const isAngle = (value, span = 180) => {
  return typeof value === "number" && value >= span * -1 && value <= span;
};
const isEmail = (value) => {
  if (typeof value !== "string" || value.length === 0) {
    return false;
  }
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(value);
};
const isLngLat = (value) => {
  if (!Array.isArray(value) || value.length !== 2) {
    return false;
  }
  const [longitude, latitude] = value;
  if (!isAngle(longitude, 180) || !isAngle(latitude, 90)) {
    return false;
  }
  return true;
};
const isMapBounds = (value) => {
  return Array.isArray(value) && value.length === 2 && value.every(isLngLat);
};
const isPassword = (value) => {
  return typeof value === "string" && value.length >= 8 && /[a-z]/.test(value) && /[A-Z]/.test(value) && /[0-9]/.test(value);
};
const isPeriod = (value) => {
  return value === ContributionPeriod.Monthly || value === ContributionPeriod.Annually;
};
const isSlug = (value) => {
  return typeof value === "string" && value === slug(value);
};
function isType(types, value) {
  return types.includes(typeof value);
}
const isURL = (value) => {
  if (typeof value !== "string" || !value.length) {
    return false;
  }
  const urlRegex = /^(https?:\/\/)([\da-z\-]+\.)+[a-z]{2,6}(:[0-9]{1,5})?([\/\w \.-]*)*\/?(\?[a-zA-Z0-9=&]*)?$/;
  return urlRegex.test(value);
};
const isNumber = (value) => {
  return typeof value === "number" && !isNaN(value);
};
const isAmountOfMoney = (value) => {
  if (!isNumber(value) || value < 0) {
    return false;
  }
  const decimalPart = value.toString().split(".")[1];
  if (decimalPart && decimalPart.length > 2) {
    return false;
  }
  return true;
};
const isPhoneNumber = (value) => {
  if (typeof value !== "string" || !value.length) {
    return false;
  }
  if (value.length > 15 || value.length < 5) {
    return false;
  }
  const phoneRegex = /^(\+?[0-9])\d*$/;
  return phoneRegex.test(value);
};
const isValidPayFee = (value, amount, period) => {
  if (typeof value !== "boolean" || typeof amount !== "number" || !isPeriod(period)) {
    return false;
  }
  if (value && period === ContributionPeriod.Annually) {
    return false;
  }
  if (!value && period === ContributionPeriod.Monthly && amount === 1) {
    return false;
  }
  return true;
};
const isNumberInRange = (value, min, max) => {
  if (!isNumber(value)) {
    return false;
  }
  if (typeof min === "number") {
    if (value < min) {
      return false;
    }
  }
  if (typeof max === "number") {
    if (value > max) {
      return false;
    }
  }
  return true;
};
const isTextInRange = (value, minLength = 0, maxLength) => {
  if (typeof value !== "string") {
    return false;
  }
  if (typeof minLength === "number" && value.length < minLength) {
    return false;
  }
  if (typeof maxLength === "number" && value.length > maxLength) {
    return false;
  }
  return true;
};
const isTextInWordRange = (value, minWordLength = 0, maxWordLength) => {
  if (typeof value !== "string") {
    return false;
  }
  const words = value.length > 0 ? value.split(" ") : [];
  if (typeof minWordLength === "number" && words.length < minWordLength) {
    return false;
  }
  if (typeof maxWordLength === "number" && words.length > maxWordLength) {
    return false;
  }
  return true;
};
export {
  isAmountOfMoney,
  isAngle,
  isEmail,
  isLngLat,
  isMapBounds,
  isNumber,
  isNumberInRange,
  isPassword,
  isPeriod,
  isPhoneNumber,
  isSlug,
  isTextInRange,
  isTextInWordRange,
  isType,
  isURL,
  isValidPayFee
};
