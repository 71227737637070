import { BaseClient } from "./base.client.js";
import { cleanUrl } from "../utils/index.js";
class CalloutReviewerClient extends BaseClient {
  constructor(options) {
    super({
      ...options,
      path: cleanUrl(options.path + "/callout")
    });
    this.options = options;
  }
  /**
   * Deserialize a reviewer
   */
  deserialize(data) {
    return {
      ...data,
      contact: {
        ...data.contact,
        joined: CalloutReviewerClient.deserializeDate(data.contact.joined),
        lastSeen: CalloutReviewerClient.deserializeDate(data.contact.lastSeen)
      }
    };
  }
  /**
   * Get all reviewers for a callout
   * @param calloutId The ID or slug of the callout
   * @returns List of reviewers
   */
  async list(calloutId) {
    const { data } = await this.fetch.get(
      `/${calloutId}/reviewers`
    );
    return data.map((reviewer) => this.deserialize(reviewer));
  }
  /**
   * Add a reviewer to a callout
   * @param calloutId The ID or slug of the callout
   * @param contactId The ID of the contact to add as reviewer
   */
  async add(calloutId, contactId) {
    await this.fetch.post(`/${calloutId}/reviewers`, { contactId });
  }
  /**
   * Remove a reviewer from a callout
   * @param calloutId The ID or slug of the callout
   * @param reviewerId The ID of the reviewer to remove
   */
  async delete(calloutId, reviewerId) {
    await this.fetch.delete(`/${calloutId}/reviewers/${reviewerId}`);
  }
}
export {
  CalloutReviewerClient
};
