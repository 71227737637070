import { isDateBetween } from "../utils/index.js";
const calloutComponentInputDateTimeValidator = (schema, answer) => {
  if (typeof answer !== "string") {
    return false;
  }
  return isDateBetween(answer, schema.widget.minDate, schema.widget.maxDate);
};
export {
  calloutComponentInputDateTimeValidator
};
