var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import { Fetch, parseISO } from "../utils/index.js";
class BaseClient {
  /**
   * Creates a new base client instance
   * @param options - Configuration options for the client
   * @param options.path - Base path for API endpoints
   * @param options.host - API host URL
   * @param options.token - Authentication token
   */
  constructor(options) {
    this.options = options;
    /** Instance of the Fetch utility for making HTTP requests */
    __publicField(this, "fetch");
    this.fetch = new Fetch({
      ...options,
      basePath: options.path
    });
  }
  static deserializeDate(s) {
    if (s instanceof Date) {
      return s;
    }
    return s == null ? s : parseISO(s);
  }
}
export {
  BaseClient
};
