var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
class CookiePolyfill {
  /**
   * Check if cookies should be used
   */
  static shouldBeUsed(credentials) {
    return !this.hasNativeCookieSupport && (credentials === "include" || credentials === "same-origin");
  }
  /**
   * Parse and validate a cookie string
   */
  static parseCookie(cookie) {
    if (!cookie || typeof cookie !== "string") return null;
    try {
      if (!cookie.includes("=")) return null;
      const lowerCookie = cookie.toLowerCase();
      if (lowerCookie.includes("expires=")) {
        const match = /expires=([^;]+)/.exec(lowerCookie);
        if (match) {
          const expiresDate = new Date(match[1]);
          if (isNaN(expiresDate.getTime()) || expiresDate < /* @__PURE__ */ new Date()) {
            return null;
          }
        }
      }
      return cookie;
    } catch (error) {
      console.warn("Failed to parse cookie:", error);
      return null;
    }
  }
  /**
   * Store cookies with validation
   */
  static store(cookies) {
    if (!Array.isArray(cookies)) return;
    for (const cookie of cookies) {
      const validCookie = this.parseCookie(cookie);
      if (validCookie) {
        this.cookies.add(validCookie);
      }
    }
  }
  /**
   * Get stored cookies
   */
  static get() {
    return Array.from(this.cookies);
  }
  /**
   * Clear stored cookies
   */
  static clear() {
    this.cookies.clear();
  }
}
// Global cookie storage for all instances
__publicField(CookiePolyfill, "cookies", /* @__PURE__ */ new Set());
/**
 * Checks if we're in a browser-like environment with proper cookie handling
 */
__publicField(CookiePolyfill, "hasNativeCookieSupport", !!(typeof globalThis !== "undefined" && globalThis.document?.cookie !== void 0));
export {
  CookiePolyfill
};
