import { BaseClient } from "./base.client.js";
import { cleanUrl } from "../utils/index.js";
class ContactMfaClient extends BaseClient {
  constructor(options) {
    super({
      ...options,
      path: cleanUrl(options.path + "/contact")
    });
    this.options = options;
  }
  /**
   * Deserialize MFA data.
   * @param data - The serialized MFA data.
   * @returns The deserialized MFA data.
   */
  static deserialize(data) {
    return data;
  }
  /**
   * Fetch the MFA data for a specific contact.
   * @param contactId - The ID of the contact.
   * @returns A promise that resolves to the contact's MFA data.
   */
  async get(contactId) {
    const { data } = await this.fetch.get(
      `/${contactId}/mfa`
    );
    return ContactMfaClient.deserialize(data);
  }
  /**
   * Create a new MFA entry for a contact.
   * @param contactId - The ID of the contact.
   * @param newData - The data required to create the MFA.
   * @returns A promise that resolves when the MFA is created.
   */
  async create(contactId, newData) {
    await this.fetch.post(`/${contactId}/mfa`, newData);
  }
  /**
   * Delete an MFA entry for a contact.
   * @param contactId - The ID of the contact.
   * @param deleteData - The data required to delete the MFA.
   * @returns A promise that resolves when the MFA is deleted.
   */
  async delete(contactId, deleteData) {
    await this.fetch.delete(`/${contactId}/mfa`, deleteData);
  }
}
export {
  ContactMfaClient
};
