var ItemStatus = /* @__PURE__ */ ((ItemStatus2) => {
  ItemStatus2["Draft"] = "draft";
  ItemStatus2["Scheduled"] = "scheduled";
  ItemStatus2["Open"] = "open";
  ItemStatus2["Ended"] = "ended";
  return ItemStatus2;
})(ItemStatus || {});
export {
  ItemStatus
};
